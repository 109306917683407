<template>
  <div class="page-wrapper-md">
    <div v-for="(val, key) in sections" :key="key">
      <h3 class="color-darkgrey">
        {{ key }}
      </h3>
      <div class="nexcard mt-3 mb-5 d-flex flex-column flex-md-row">
        <div class="row">
          <div
            v-for="(vid, index) in val"
            :key="index"
            :class="['col-12', { 'col-md-6 col-lg-6': val.length > 1 }]"
          >
            <video
              :poster="vid.poster"
              class="rounded shadow img-fluid"
              controls
              controlslist="nodownload noplaybackrate"
            >
              <source
                :src="
                  // required due to inconsistent video URL
                  key == 'Welcome Abroad!'
                    ? vid.video
                    : `${videoSource}${vid.video}`
                "
                type="video/mp4"
              />
            </video>
            <div v-if="vid.subtitle" class="mt-2 mb-4">
              <h5>
                {{ vid.subtitle }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sections: {
        "Welcome Abroad!": [
          {
            poster: "WelcomeAbroad.png",
            video:
              "https://nexmind-public-resources.s3.ap-southeast-1.amazonaws.com/Nexlaw+Onboarding+Video.mp4",
          },
        ],
        "NexLaw Dashboard Overview": [
          {
            poster: "Overview.png",
            video: "NexLaw+Video+-+Dashboard+Overview_V2.mp4",
          },
        ],
        "Law Features": [
          {
            poster: "caseLawSearch.png",
            video: "NexLaw+Video+-+Case+law+Search+2024.mp4",
            subtitle: "Case Law Search",
          },
          {
            poster: "legislationSearch.png",
            video: "NexLaw+Video+-+Legislation+Search_Jan+24.mp4",
            subtitle: "Legislation Search",
          },
          {
            poster: "caseSummerizer.png",
            video: "NexLaw+Video+-+Case+Law+Summarizer+2024.mp4",
            subtitle: "Case Law Summarizer",
          },
          {
            poster: "contractReview.png",
            video: "NexLaw+Video_Contract+Review_Jan+30124.mp4",
            subtitle: "Contract Review",
          },
        ],
        "Legal Writer": [
          {
            poster: "lodLegalWriter.png",
            video: "NexLaw+Video_Legal+Writer+LOD_Jan+24.mp4",
            subtitle: "Letter of Demand",
          },
          {
            poster: "agreementLegalWriter.png",
            video: "NexLaw+Video_Legal+Writer+Agr_Jan+24.mp4",
            subtitle: "Agreement",
          },
        ],
        "Legal Research": [
          {
            poster: "legalArgument.png",
            video: "NexLaw+Video_Legal+Res+Argue_Jan+24.mp4",
            subtitle: "Legal Argument",
          },
          {
            poster: "LegalMemo.png",
            video: "NexLaw+Video_Legal+Res+Memo1_Jan+24.mp4",
            subtitle: "Legal Memo & Opinion",
          },
        ],
      },

      videoSource:
        "https://nexmind-public-resources.s3.ap-southeast-1.amazonaws.com/v5/",
    };
  },

  async created() {
    for (const [key, value] of Object.entries(this.sections)) {
      for (const [index, obj] of value.entries()) {
        const posterPath = await import(`@/assets/video/${obj.poster}`);
        this.$set(
          this.sections[key][index],
          "poster",
          posterPath.default || posterPath
        );
      }
    }
  },
};
</script>

<style lang="css">
@media screen and (min-width: 1200px) {
  video {
    height: 247.5px !important;
    background-image: linear-gradient(to right, #010b6b, #2271c1);
  }
}
</style>
